import { useCallback, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import "../components/providers/OrderProvider";
import PaymentCard from "../components/payment/PaymentCard";
import { OrderContext } from "../components/providers/OrderProvider";

const PaymentChoice = ({ step }) => {
  const navigate = useNavigate();
  const { order, setOrder } = useContext(OrderContext);
  const hasBasketProducts = order.basket.length > 0;

  const onLogoClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onSelectPaymentMethod = useCallback((method) => {
    localStorage.setItem(
      "order",
      JSON.stringify({
        ...order,
        paymentMethod: method,
      })
    );
    setOrder((order) => ({
      ...order,
      paymentMethod: method,
    }));
    navigate("/delivery-choice");
  }, []);

  useEffect(() => {
    if (!order || !hasBasketProducts) {
      navigate("/");
    }
  }, [order]);

  return (
    <div className="relative bg-color-white w-full overflow-hidden flex flex-col items-center justify-start">
      <Navbar onLogoClick={onLogoClick} step={step} />
      <main className="self-stretch flex flex-col pt-[2rem] px-[2.5rem] pb-[2.5rem] items-center justify-start gap-[2.5rem] sm:self-stretch sm:w-auto sm:pt-[1.5rem] sm:px-[1.5rem] sm:pb-[2rem] sm:box-border">
        <header className="self-stretch bg-color-white flex flex-col items-center justify-start gap-2 max-w-[40rem] my-0 mx-auto text-center text-[1.5rem] text-color-black font-ivy-mode sm:self-stretch sm:w-auto sm:gap-[0.5rem]">
          <h1 className="m-0 self-stretch relative text-[inherit] leading-[150%] font-bold font-inherit md:text-[1.5rem] sm:text-[1.5rem] sm:text-color-black">
            Jak zaplatíš?
          </h1>
          <p className="cursor-pointer [border:none] py-0 my-0 px-[1rem] bg-[transparent] rounded-full flex flex-row items-start justify-start">
            <div className="relative text-[1.25rem] leading-[150%] font-graphie text-dimgray text-center md:text-[1.13rem] sm:text-[1.13rem]">
              Zvol platební metodu
            </div>
          </p>
        </header>
        <div className="ml-0 pl-0 w-full grid grid-cols-[16.625rem_16.625rem] md:grid-cols-[1fr] gap-6 justify-center list-none">
          <PaymentCard
            name="Apple pay"
            iconSrc="/apple-pay.svg"
            onSelectPaymentMethod={()=>onSelectPaymentMethod("stripe")}
          />
          <PaymentCard
            name="Google Pay"
            iconSrc="/google-pay.svg"
            onSelectPaymentMethod={()=>onSelectPaymentMethod("stripe")}
          />
          <PaymentCard
            name="Kartou online"
            iconSrc="/card-pay.svg"
            onSelectPaymentMethod={()=>onSelectPaymentMethod("stripe")}
          />
          <PaymentCard
            name="Bankovní převod"
            iconSrc="/bank-transfer-pay.svg"
            onSelectPaymentMethod={()=>onSelectPaymentMethod("bankTransfer")}
          />
        </div>
      </main>
    </div>
  );
};

export default PaymentChoice;
