import { useCallback, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import OrderForm from "../components/OrderForm";
import { OrderContext } from "../components/providers/OrderProvider";

const ZasilkovnaHome = ({ step }) => {
  const { order, setOrder } = useContext(OrderContext);
  const navigate = useNavigate();

  const onLogoClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onBackButtonClick = useCallback(() => {
    navigate("/delivery-choice");
  }, [navigate]);

  useEffect(() => {
    const shippingPrice =
      order.subTotal > 800 ? 0 : order.shipping.initialPrice;
    if (!order.shipping.initialPrice) {
      navigate("/delivery-choice");
      return;
    }
    localStorage.setItem(
      "order",
      JSON.stringify({
        ...order,
        total: order.subTotal + shippingPrice,
        shipping: {
          ...order.shipping,
          method: "zasilkovna-home",
          price: shippingPrice,
        },
      })
    );

    setOrder((order) => ({
      ...order,
      total: order.subTotal + shippingPrice,
      shipping: {
        ...order.shipping,
        method: "zasilkovna-home",
        price: shippingPrice,
      },
    }));
  }, [order.subTotal]);

  return (
    <div className="relative bg-color-white w-full overflow-hidden flex flex-col items-center justify-start">
      <Navbar onLogoClick={onLogoClick} step={step} />
      <main className="self-stretch flex flex-col pt-[2rem] px-[2.5rem] pb-[2.5rem] items-center justify-start gap-[2.5rem] sm:self-stretch sm:w-auto sm:pt-[1.5rem] sm:px-[1.5rem] sm:pb-[2rem] sm:box-border">
        <header className="self-stretch bg-color-white flex flex-col items-center justify-start gap-[1rem] max-w-[40rem] my-0 mx-auto text-center text-[1.5rem] text-color-black font-ivy-mode sm:self-stretch sm:w-auto sm:gap-[0.5rem]">
          <h1 className="m-0 self-stretch relative text-[inherit] leading-[150%] font-bold font-inherit md:text-[1.5rem] sm:text-[1.5rem] sm:text-color-black">
            Kam barvy zašleme?
          </h1>
          <button
            className="cursor-pointer [border:none] py-[0.56rem] px-[1rem] bg-[transparent] rounded-full flex flex-row items-start justify-start"
            onClick={onBackButtonClick}
          >
            <div className="relative text-[1.25rem] leading-[150%] font-graphie text-dimgray text-center md:text-[1.13rem] sm:text-[1.13rem]">
              ← Zásilkovna domů
            </div>
          </button>
        </header>
        <OrderForm />
      </main>
    </div>
  );
};

export default ZasilkovnaHome;
