import { useCallback, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import OrderForm from "../components/OrderForm";
import { OrderContext } from "../components/providers/OrderProvider";

const ZasilkovnaPickup = ({ step }) => {
  const { order, setOrder } = useContext(OrderContext);

  const [selectedPoint, setSelectedPoint] = useState(
    order.shipping.zasilkovnaPoint
  );

  const isPointSelected = selectedPoint || false;

  const packetaOptions = {
    country: "cz,sk",
    language: "cs",
    valueFormat: '"Packeta",id,carrierId,carrierPickupPointId,name,city,street',
    defaultPrice: "79",
    defaultCurrency: "CZK",
  };

  const showSelectedPickupPoint = (point) => {
    if (point) {
      console.log("Selected point", point);
      setSelectedPoint(point);

      localStorage.setItem("shippingSetTime", Date.now());

      localStorage.setItem(
        "order",
        JSON.stringify({
          ...order,
          shipping: { ...order.shipping, zasilkovnaPoint: point },
        })
      );

      setOrder((order) => ({
        ...order,
        shipping: { ...order.shipping, zasilkovnaPoint: point },
      }));
    }
  };

  const navigate = useNavigate();

  const onLogoClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onBackButtonContainerClick = useCallback(() => {
    navigate("/delivery-choice");
  }, [navigate]);

  const onSelectPickupButtonClick = useCallback(() => {
    Packeta.Widget.pick(
      process.env.REACT_APP_PACKETA_API_KEY, //TODO: Make safe
      showSelectedPickupPoint,
      packetaOptions
    );
  }, []);

  useEffect(() => {
    const timestamp = localStorage.getItem("shippingSetTime");
    const diff = (Date.now() - timestamp) / 1000 / 60;

    // If point was selected more than 2 mins ago, ask user to select again
    if (diff > 2) {
      setSelectedPoint();

      localStorage.setItem(
        "order",
        JSON.stringify({
          ...order,
          shipping: { ...order.shipping, zasilkovnaPoint: {} },
        })
      );

      setOrder((order) => ({
        ...order,
        shipping: { ...order.shipping, zasilkovnaPoint: {} },
      }));

      onSelectPickupButtonClick();
    }
  }, []);

  useEffect(() => {
    const shippingPrice =
      order.subTotal > 800 ? 0 : order.shipping.initialPrice;
    if (!order.shipping.initialPrice) {
      navigate("/delivery-choice");
      return;
    }
    localStorage.setItem(
      "order",
      JSON.stringify({
        ...order,
        total: order.subTotal + shippingPrice,
        shipping: {
          ...order.shipping,
          method: "zasilkovna-pickup",
          price: shippingPrice,
        },
      })
    );

    setOrder((order) => ({
      ...order,
      total: order.subTotal + shippingPrice,
      shipping: {
        ...order.shipping,
        method: "zasilkovna-pickup",
        price: shippingPrice,
      },
    }));
  }, [order.subTotal]);

  return (
    <div className="relative bg-color-white w-full overflow-hidden flex flex-col items-center justify-start">
      <Navbar onLogoClick={onLogoClick} step={step} />
      <main className="self-stretch flex flex-col pt-[2rem] px-[2.5rem] pb-[2.5rem] items-center justify-start gap-[2.5rem] sm:self-stretch sm:w-auto sm:pt-[1.5rem] sm:px-[1.5rem] sm:pb-[2rem] sm:box-border">
        <header className="self-stretch bg-color-white flex flex-col items-center justify-start gap-[1rem] max-w-[40rem] my-0 mx-auto text-center text-[1.5rem] text-color-black font-ivy-mode sm:self-stretch sm:w-auto sm:gap-[0.5rem]">
          <h1 className="m-0 self-stretch relative text-[inherit] leading-[150%] font-bold font-inherit md:text-[1.5rem] sm:text-[1.5rem] sm:text-color-black">
            Kam barvy zašleme?
          </h1>
          <div
            className="rounded-full flex flex-row py-[0.56rem] px-[1rem] items-start justify-start cursor-pointer text-[1.25rem] text-dimgray font-graphie"
            onClick={onBackButtonContainerClick}
          >
            <div className="packeta-selector-open relative leading-[150%] md:text-[1.13rem] sm:text-[1.13rem]">
              ← Zásilkovna výdejna
            </div>
          </div>
        </header>
        {!isPointSelected && (
          <button
            className="hover:bg-gray cursor-pointer [border:none] py-[1.19rem] px-[2.5rem] bg-color-black rounded-full overflow-hidden flex flex-row items-center justify-center"
            onClick={onSelectPickupButtonClick}
          >
            <b className="relative text-[1rem] font-graphie text-color-white text-center">
              Vybrat výdejnu…
            </b>
          </button>
        )}
        {isPointSelected && (
          <>
            <article className="w-full self-stretch rounded-3xl bg-color-white box-border overflow-hidden flex flex-row p-[1.5rem] items-start justify-start gap-[1rem] max-w-[40rem] my-0 mx-auto text-left text-[1rem] text-color-black font-graphie border-[1px] border-solid border-color-grey-200 sm:self-stretch sm:flex-col sm:gap-[1.5rem] sm:items-start sm:justify-start">
              <div className="flex-1 flex flex-col items-start justify-start gap-[1rem] sm:flex-[unset] sm:self-stretch">
                <b className="self-stretch relative leading-[150%]">
                  Zvolená zásilkovna
                </b>
                <div className="packeta-selector-value self-stretch relative leading-[150%] text-dimgray">
                  {selectedPoint.place}
                  <br />
                  {selectedPoint.street}, {selectedPoint.zip}&nbsp;&nbsp;
                  {selectedPoint.city}
                </div>
              </div>
              <button
                className="hover:bg-blanchedalmond cursor-pointer [border:none] py-[0.75rem] pr-[2rem] pl-[1.5rem] bg-color-beige rounded-full flex flex-row items-start justify-start gap-[0.5rem]"
                onClick={onSelectPickupButtonClick}
              >
                <img
                  className="relative w-[1rem] h-[1rem] overflow-hidden shrink-0"
                  alt=""
                  src="/lucideedit.svg"
                />
                <div className="relative text-[1rem] leading-[100%] font-semibold font-graphie text-color-black text-left">
                  Změnit
                </div>
              </button>
            </article>
            <OrderForm />
          </>
        )}
      </main>
    </div>
  );
};

export default ZasilkovnaPickup;
