import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import { OrderContext } from "../components/providers/OrderProvider";

export const DeliveryMethodsIds = {
  home: "recBtay0xpN0I6N6D",
  pickup: "recLxAwbfqagE6Wjc",
};

const DeliveryChoice = ({ step }) => {
  const { order, setOrder } = useContext(OrderContext);
  const [isLoading, setIsLoading] = useState(true);

  const [deliveryMethods, setDeliveryMethods] = useState([]);

  useEffect(() => {
    var Airtable = require("airtable");
    var base = new Airtable({
      apiKey: process.env.REACT_APP_AIRTABLE_SECRET_API_TOKEN_READ,
    }).base(process.env.REACT_APP_AIRTABLE_BASE_ID);

    const list = [];

    base("Doručovací metody")
      .select({
        view: "Pouze Aktivní",
      })
      .eachPage(
        function page(records, fetchNextPage) {
          records.forEach(function (record) {
            if (record.get("Aktivní")) {
              // Check if the record's ID matches any of the provided IDs
              if (
                record.id === DeliveryMethodsIds.home ||
                record.id === DeliveryMethodsIds.pickup
              ) {
                list.push({
                  id: record.id,
                  name: record.get("Název"),
                  price: record.get("Cena"),
                  formatedPrice: record.get("Cena (formátovaná)"),
                  image: record.get("Logo")[0]["thumbnails"]["large"]["url"],
                });
              }
            }
          });
          fetchNextPage();
        },
        function done(err) {
          setIsLoading(false);
          if (err) {
            console.error(err);
            return;
          }
          // Update state with the filtered list of delivery methods
          setDeliveryMethods(list);
        }
      );
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    if (!order) {
      navigate("/");
    }
    if (!order.paymentMethod) {
      navigate("/payment-choice");
    } else if (
      order.paymentMethod === "stripe" ||
      order.paymentMethod === "bankTransfer"
    ) {
      return;
    }
    navigate("/payment-choice");
  }, [navigate]);

  const onLogoClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onSelectDeliveryMethod = useCallback((method) => {
    if (method.id === DeliveryMethodsIds.home) {
      onShippingMethodContainer1Click(method);
    }
    if (method.id === DeliveryMethodsIds.pickup) {
      onShippingMethodContainerClick(method);
    }
  });

  const onShippingMethodContainerClick = useCallback(
    (method) => {
      const shippingPrice = order.subTotal > 800 ? 0 : method.price;
      localStorage.setItem(
        "order",
        JSON.stringify({
          ...order,
          total: order.subTotal + shippingPrice,
          shipping: {
            ...order.shipping,
            initialPrice: method.price,
            method: "zasilkovna-pickup",
            price: shippingPrice,
          },
        })
      );

      setOrder((order) => ({
        ...order,
        total: order.subTotal + shippingPrice,
        shipping: {
          ...order.shipping,
          initialPrice: method.price,
          method: "zasilkovna-pickup",
          price: shippingPrice,
        },
      }));

      navigate("/zasilkovna-pickup");
    },
    [navigate]
  );

  const onShippingMethodContainer1Click = useCallback(
    (method) => {
      const shippingPrice = order.subTotal > 800 ? 0 : method.price;
      localStorage.setItem(
        "order",
        JSON.stringify({
          ...order,
          total: order.subTotal + shippingPrice,
          shipping: {
            ...order.shipping,
            initialPrice: method.price,
            method: "zasilkovna-home",
            price: shippingPrice,
          },
        })
      );

      setOrder((order) => ({
        ...order,
        total: order.subTotal + shippingPrice,
        shipping: {
          ...order.shipping,
          initialPrice: method.price,
          method: "zasilkovna-home",
          price: shippingPrice,
        },
      }));

      navigate("/zasilkovna-home");
    },
    [navigate]
  );

  return (
    <div className="relative bg-color-white w-full overflow-hidden flex flex-col items-center justify-start">
      <Navbar onLogoClick={onLogoClick} step={step} />
      <main className="self-stretch flex flex-col pt-[2rem] px-[2.5rem] pb-[2.5rem] items-center justify-start gap-[2.5rem] text-center text-[1rem] text-color-black font-graphie sm:pt-[1.5rem] sm:pl-[1.5rem] sm:pr-[1.5rem] sm:pb-[2rem] sm:box-border">
        <header className="w-full self-stretch bg-color-white flex flex-col items-center justify-start gap-[1.5rem] max-w-[40rem] my-0 mx-auto text-center text-[1.5rem] text-color-black font-ivy-mode sm:gap-[1rem]">
          <h1 className="m-0 self-stretch relative text-[inherit] leading-[150%] font-bold font-inherit md:text-[1.5rem] sm:text-[1.5rem] sm:text-color-black">
            Kam barvy zašleme?
          </h1>
          <div className="relative text-[1.25rem] leading-[150%] font-graphie text-dimgray md:text-[1.13rem]">
            Zvol doručovací metodu
          </div>
        </header>
        {isLoading && (
          <div className="min-h-[20rem] w-full flex items-center justify-center">
            <span className="w-[3rem] h-[3rem] border-solid rounded-full border-[0.25rem] border-color-black border-r-transparent animate-spin"></span>
          </div>
        )}
        {!isLoading && (
          <div className="w-full max-w-[40rem] my-0 mx-auto self-stretch overflow-hidden flex flex-row items-start justify-center gap-[1.5rem] sm:flex-col sm:gap-[1.5rem] sm:items-start sm:justify-center">
            {deliveryMethods.map((method) => (
              <div
                className="flex-1 rounded-3xl bg-color-white overflow-hidden flex flex-col p-[2.5rem] items-center justify-start gap-[1.5rem] cursor-pointer border-[1px] border-solid border-color-grey-200 hover:bg-color-grey-100 hover:box-border hover:border-[1px] hover:border-solid hover:border-color-grey-100 sm:p-[1.5rem] sm:box-border sm:flex-[unset] sm:self-stretch"
                onClick={() => onSelectDeliveryMethod(method)}
              >
                <div className="rounded-full w-[4.5rem] h-[4.5rem] overflow-hidden shrink-0 flex flex-row items-center justify-center">
                  <img
                    className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full"
                    alt=""
                    src={method.image}
                  />
                </div>
                <div className="self-stretch flex flex-col items-center justify-start gap-[0.5rem]">
                  <div className="relative leading-[120%] font-semibold flex items-center justify-center w-[11.63rem]">
                    {method.name}
                  </div>
                  <div className="relative leading-[120%] text-dimgray flex items-center justify-center w-[11.63rem]">
                    {order.subTotal > 800 ? (
                      <span>
                        <span className="line-through">
                          {method.formatedPrice}
                        </span>{" "}
                        Zdarma
                      </span>
                    ) : (
                      method.formatedPrice
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </main>
    </div>
  );
};

export default DeliveryChoice;
